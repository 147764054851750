import ConfigActions from '../actions/ConfigActions';
import {categoryEnum} from '../../constants/category';
import colorGenerator from '../../utils/colorGenerator';
import {
    getRangeByGranularity,
    getChartDataRange,
    createXAxis,
    createYAxis,
    createRateSeriesNormal, createRateSeriesForecast, createSpreadSeries
} from './configLib';
import {
    getSequencePointMoment
} from '../../utils/BL';
import {getTemplateLeftPoint, getTemplateRightPoint} from '../selectors/chart';
import moment from 'moment';

export const CONFIG_INITIAL_STATE = {
    grid: {
        left: '64',
        right: '64',
        top: '18',
        shadowColor: '#878C96',
        shadowBlur: 0,
        show: true
    },
    xAxis: [],
    yAxis: [],
    // dataZoom: [],
    series: []
};

function templateHaveVolume(template) {
    return template.tradeLanes.some((tradeLane) => {
        return tradeLane.models.some((model) => {
            return model.modelInstances.some((modelInstance) => {
                return modelInstance.timeSeries.category === categoryEnum.volume;
            });
        });
    });
}

function templateHaveRate(template) {
    return template.tradeLanes.some((tradeLane) => {
        return tradeLane.models.some((model) => {
            return model.modelInstances.some((modelInstance) => {
                return modelInstance.timeSeries.category === categoryEnum.rate;
            });
        });
    });
}

function config (state = CONFIG_INITIAL_STATE, action, template) {
    switch (action.type) {
        case ConfigActions.UPDATE_CHART_DATA: {
            if (!templateHaveRate(template)) {
                return config(state, {...action, type: ConfigActions.CLEAR_CHART_CONFIG}, template);
            }

            const haveVolume = templateHaveVolume(template);
            const scaleStartMoment = getSequencePointMoment({point: getTemplateLeftPoint({chart: {template}}), granularity: template.granularity, startingDate: {year: 2000}});
            const scaleEndMoment = getSequencePointMoment({point: getTemplateRightPoint({chart: {template}}), granularity: template.granularity, startingDate: {year: 2000}});
            const xAxisRange = getRangeByGranularity(template.granularity, scaleStartMoment, scaleEndMoment);


            // if (haveVolume) {
            //     xAxis.push(createXAxis(1, xAxisRange));
            //     yAxis.push(createYAxis(1));
            // }

            const series = [];
            const colorIterator = colorGenerator();

            template.tradeLanes.forEach((tradeLane) => {
                tradeLane.models.forEach((model) => {
                    model.modelInstances.forEach((modelInstance) => {
                        if (modelInstance.timeSeries.category === categoryEnum.rate) {
                            let seriesColor = colorIterator.next().value;
                            series.push(
                                createRateSeriesNormal(
                                    `data series rate: ${modelInstance.timeSeries.name}`,
                                    modelInstance.timeSeries.dataPoints
                                        .filter(e=>e.refDate)
                                        .map(e=>{return [e.refDate, e.value]}),
                                    seriesColor
                                )
                            );

                            series.push(
                                createRateSeriesForecast(
                                    `forecast series rate: ${modelInstance.forecast.name}`,
                                    modelInstance.forecast.forecastPoints.map(e=>{return [e.refDate, e.value]}),
                                    seriesColor
                                )
                            );

                            series.push(createSpreadSeries(
                                `forecast spread series rate`,
                                modelInstance.forecast.forecastPoints.map(e=>{return [e.refDate, e.percentile5, e.percentile95]})
                            ))

                        } else if (modelInstance.timeSeries.category === categoryEnum.volume) {
                            //todo: volume
                        }
                    })
                })
            });
            const dataZoom = [
                {
                    show: false,
                    type: 'inside',
                    realtime: true,
                    xAxisIndex: [0],
                    start: 0,
                    end: series[0].data.length
                },
                {
                    show: false,
                    realtime: true,
                    xAxisIndex: [0],
                    start: 0,
                    end: series[0].data.length
                }
            ];

            return {
                ...state,
                xAxis: [createXAxis(0)],
                yAxis: [createYAxis(0)],
                series
            };
        }
        case ConfigActions.CLEAR_CHART_CONFIG: {
            return {
                ...state,
                series: []
            }
        }
        default: {
            return {...state};
        }
    }
}

export default config;
