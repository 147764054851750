/* @flow */

import React, {Component} from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';
import './ChartMenu.scss';
import ChartActions from '../../../../services/actions/ChartActions';
import ArrowLeft from '../../../../icons/ArrowLeft';
import Edit from '../../../../icons/Edit';
import AddData from '../../../../icons/AddData';
import Granularity from '../../../../icons/Granularity';
import Compare from '../../../../icons/Compare';
import Chart from '../../../../icons/Chart';
import Prediction from '../../../../icons/Prediction';
import {getTemplateComparison, getTemplateGranularity, getTemplateViewMode} from '../../../../services/selectors/chart';
import {granularityEnum} from '../../../../constants/granularity';
import {viewModeEnum} from '../.././../../constants/viewMode';
import {Link} from "react-router-dom";

type Props = {
    dispatch: Function,
    granularity: string,
    comparison: boolean,
    viewMode: string
};

function mapStateToProps(state) {
    return {
        granularity: getTemplateGranularity(state),
        comparison: getTemplateComparison(state),
        viewMode: getTemplateViewMode(state)
    }
}

class ChartMenu extends Component<Props> {
    render() {
        const {
            viewMode,
            granularity,
            comparison
        } = this.props;

        const chartOptionClasses = classNames('chart-menu__option-value', {
            'chart-menu__option-value--active': viewMode === viewModeEnum.chart
        });
        const tableOptionClasses = classNames('chart-menu__option-value', {
            'chart-menu__option-value--active': viewMode === viewModeEnum.table
        });
        const weekOptionClasses = classNames('chart-menu__option-value', {
            'chart-menu__option-value--active': granularity === granularityEnum.week
        });
        const monthOptionClasses = classNames('chart-menu__option-value', {
            'chart-menu__option-value--active': granularity === granularityEnum.month
        });
        const quarterOptionClasses = classNames('chart-menu__option-value', {
            'chart-menu__option-value--active': granularity === granularityEnum.quarter
        });

        return (
            <div className='chart-menu'>
                <div className='chart-menu__group'>
                    <div className='chart-menu__left'>
                        <div className='chart-menu__back'>
                            <Link to={"/"}>
                                <ArrowLeft />
                            </Link>
                        </div>
                        <div className='chart-menu__header-group'>
                            New rate forecast
                            <div className='chart-menu__edit'>
                                <Edit />
                            </div>
                        </div>
                    </div>
                    <div className='chart-menu__right'>
                        <div className='chart-menu__status-group'>
                            <div className='chart-menu__status-text'>
                                Autosaved
                            </div>
                            <div className='chart-menu__status-text'>
                                2 minutes ago
                            </div>
                        </div>
                        <div className='chart-menu__btn'>
                            Save
                        </div>
                        <div className='chart-menu__btn chart-menu__btn--filled'>
                            Export
                        </div>
                    </div>
                </div>
                <div className='chart-menu__group'>
                    <div className='chart-menu__left'>
                        <div className='chart-menu__option-group'>
                            <div className='chart-menu__option-label chart-menu__option-label--btn'>
                                <AddData className='chart-menu__option-icon' />
                                <div className='chart-menu__option-text'>
                                    Add data
                                </div>
                            </div>
                        </div>
                        <div className='chart-menu__option-group'>
                            <div className='chart-menu__option-label'>
                                <Granularity className='chart-menu__option-icon' />
                                <div className='chart-menu__option-text'>
                                    Granularity
                                </div>
                            </div>
                            <div
                                className={weekOptionClasses}
                                onClick={() => this.props.dispatch(ChartActions.changeGranularity(granularityEnum.week))}
                            >
                                Week
                            </div>
                            <div
                                className={monthOptionClasses}
                                onClick={() => this.props.dispatch(ChartActions.changeGranularity(granularityEnum.month))}
                            >
                                Month
                            </div>
                            <div
                                className={quarterOptionClasses}
                                onClick={() => this.props.dispatch(ChartActions.changeGranularity(granularityEnum.quarter))}
                            >
                                Quarter
                            </div>
                        </div>
                        <div className='chart-menu__option-group'>
                            <div className='chart-menu__option-label'>
                                <Compare className='chart-menu__option-icon' />
                                <div className='chart-menu__option-text'>
                                    Comparison
                                </div>
                            </div>
                            <div
                                className='chart-menu__option-value'
                                onClick={() => this.props.dispatch(ChartActions.toggleComparison())}
                            >
                                {
                                    comparison ? 'On' : 'Off'
                                }
                            </div>
                        </div>
                        <div className='chart-menu__option-group'>
                            <div className='chart-menu__option-label'>
                                <Prediction className='chart-menu__option-icon' />
                                <div className='chart-menu__option-text'>
                                    Prediction
                                </div>
                            </div>
                            <div className='chart-menu__option-value'>
                                Spread
                            </div>
                        </div>
                    </div>
                    <div className='chart-menu__right'>
                        <div className='chart-menu__option-group chart-menu__option-group--last'>
                            <div className='chart-menu__option-label'>
                                <Chart className='chart-menu__option-icon' />
                                <div className='chart-menu__option-text'>
                                    View
                                </div>
                            </div>
                            <div
                                className={chartOptionClasses}
                                onClick={() => this.props.dispatch(ChartActions.changeViewMode(viewModeEnum.chart))}
                            >
                                Chart
                            </div>
                            <div
                                className={tableOptionClasses}
                                onClick={() => this.props.dispatch(ChartActions.changeViewMode(viewModeEnum.table))}
                            >
                                Table
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, )(ChartMenu);
