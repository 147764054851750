import moment from 'moment';
import qs from 'qs';

import { authHttpRequest } from '../../../../../../services/utils/authAxios';
import colorGenerator from '../../../../../../utils/colorGenerator';

const DEFAULT_SERIES_OPTIONS = {
    type: 'line',
    smooth: false,
    symbol: 'none',
    lineStyle: {
        width: 4
    },
    data: []
};


export async function getChartConfig(chart) {
    const series = await getChartData(chart);
    return {
        grid: {
            left: '0',
            right: '64',
            top: '10',
            shadowColor: '#878C96',
            shadowBlur: 0,
            show: true
        },
        xAxis: getXAxisOptions(),
        yAxis: getYAxisOptions(),
        series: getSeriesOptions(series)
    }
}

function getChartData(chart) {
    const modelPromises = [];
    const granularity = chart.granularity;
    const comparison = chart.comparison;
    const startDataPoint = chart.startDataPoint;
    const endDataPoint = chart.endDataPoint;
    const asOfDataPoint = chart.asOfDataPoint;
    const comparisonStartDataPoint = chart.comparison.startDataPoint;
    const comparisonEndDataPoint = chart.comparison.endDataPoint;

    chart.tradeLanes.tradeLanes.forEach((tradeLane) => {
        tradeLane.models.forEach((model) => {
            const queryParams = {
                granularity,
                comparison,
                startDataPoint,
                endDataPoint,
                asOfDataPoint
            };
            if (comparison) {
                queryParams.comparisonStartDataPoint = comparisonStartDataPoint;
                queryParams.comparisonEndDataPoint = comparisonEndDataPoint;
            }
            modelPromises.push(authHttpRequest({
                url: `/api/instance/${model.id}?${qs.stringify(queryParams)}`,
                timeout: 20000,
                method: 'get',
                responseType: 'json'
            }).then((response) => {
                const modelInstanceFull = response.data.data;
                return {
                    tradeLaneId: tradeLane.id,
                    modelId: model.id,
                    modelInstance: modelInstanceFull
                };
            }));
        })
    });

    return Promise.all(modelPromises).then(data => data.map(item => ({seriesData: item.modelInstance.timeSeries.dataPoints.map(item => [item.refDate, item.value])})));
}


function getXAxisOptions() {
    return {
        type: 'time',
        axisLine: {
            show: false
        },
        splitArea: {
            show: true
        },
        axisTick: {
            show: false
        },
        splitLine: {
            show: false
        },
        axisLabel: {
            show: true,
            color: '#6C7081',
            formatter: (value, index) => {
                return moment(value).format('MMM YYYY');
            }
        }
    }
}

function getYAxisOptions() {
    return {
        type: 'value',
        position: 'right',
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        splitLine: {
            lineStyle: {
                color: 'rgba(0, 0, 0, 0.1)'
            }
        },
        axisLabel: {
            color: '#6C7081'
        },
    }
}

function getSeriesOptions(series) {
    const colorIterator = colorGenerator();

    return series.map(item => (
        {
            ...DEFAULT_SERIES_OPTIONS,
            color: colorIterator.next().value,
            data: item.seriesData
        }));
}
