class ConfigActions{
    static UPDATE_CHART_DATA = 'UPDATE_CHART_DATA';
    static CLEAR_CHART_CONFIG = 'CLEAR_CHART_CONFIG';

    static calculateChart() {
        return {
            type: ConfigActions.UPDATE_CHART_DATA
        }
    }

    static clearConfig() {
        return {
            type: ConfigActions.CLEAR_CHART_CONFIG
        }
    }
}

export default ConfigActions;
