export default function* colorGenerator() {
    yield '#0A1E6E';
    yield '#F01414';
    while (true) {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        yield color;
    }
}
