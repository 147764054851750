/* @flow */

export default function (a: Array, predicate: Function) {
    for (let i = 0; i < a.length; i++) {
        if (predicate(a[i])) {
            return a[i];
        }
    }
    return null;
}
