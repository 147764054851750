import axios, {CancelToken} from 'axios';
import {redirectToLogin} from './redirectToLogin';

export function authHttpRequest(axiosOptions) {
    const cancelTokenSource = CancelToken.source();
    return axios({
        ...axiosOptions,
        timeout: 60000,
        cancelToken: cancelTokenSource.token
    }).then((response) => {
        if (response.data && response.data.status.toLowerCase() !== 'success') {
            throw new Error(response.data.message)
        }
        return response;
    })
}
