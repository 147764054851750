/* @flow */

import React from 'react';
import classNames from 'classnames';
import './Select.scss';
import loDebounce from 'lodash/debounce';
import InputS from '../../icons/Input-s';

export const eventKeyCodes = {
    tab: 9,
    enter: 13,
    escape: 27,
    space: 32,
    upArrow: 38,
    downArrow: 40
};

type Option = {
    value: any,
    id: string | number,
    label: string
}

type Props = {
    value: Option,
    clear: Function,
    onChange: Function,
    disabled: boolean,
    options: Array,
    placeholder: string
}

type State = {
    inFocus: boolean
}

class Select extends React.PureComponent<Props, State> {

    static defaultProps = {
        disabled: false
    };

    constructor(props) {
        super(props);

        this.ref = React.createRef();

        this.state = {
            inFocus: false
        };
    }

    setPushPadRef = (ref) => {
        if (!ref) {
            return;
        }
        this.ref = ref;
        const handler = loDebounce((e) => {
            if (e.type === 'focus' && !this.props.disabled) {
                this.setState({
                    inFocus: true
                });
            }
            if (e.type === 'blur' && !this.props.disabled) {
                this.setState({
                    inFocus: false
                });
            }
        }, 10);
        this.ref.addEventListener('focus', handler, true);
        this.ref.addEventListener('blur', handler, true);
    };

    pushPadKeyDown = (e) => {
        if (e.keyCode === eventKeyCodes.tab) {
            return e;
        }
        if (!this.state.inFocus && e.keyCode === eventKeyCodes.enter) {
            return e;
        }

        const {focusedIndex, inFocus} = this.state;
        const {options} = this.props;
        const {onSelect} = this;

        switch (e.keyCode) {
            case eventKeyCodes.upArrow: {
                if (!inFocus) {
                    this.setState({
                        inFocus: true
                    });
                } else {
                    //this.moveCursor('top', focusedIndex, options);
                }
                e.preventDefault();
                return e;
            }
            case eventKeyCodes.downArrow: {
                if (!inFocus) {
                    this.setState({
                        inFocus: true
                    });
                } else {
                    //this.moveCursor('down', focusedIndex, options);
                }
                e.preventDefault();
                return e;
            }
            case eventKeyCodes.space:
            case eventKeyCodes.enter: {
                if (Number.isInteger(focusedIndex) && focusedIndex >= 0) {
                    onSelect(options[focusedIndex], e);
                }
                e.preventDefault();
                return e;
            }
            case eventKeyCodes.escape: {
                this.setState({
                    inFocus: false
                });
                e.preventDefault();
                return e;
            }
            default: {
                return e;
            }
        }
    };

    onSelect = (option) => {
        this.props.onChange(option);
    };

    mouseDownOnItem = (option, index) => (e) => {
        const {disabled = false} = this.props;

        if (disabled) {
            return;
        }

        const {onSelect} = this;

        let isLeftClick = false;
        if ('buttons' in e) {
            isLeftClick = e.buttons === 1;
        } else {
            const button = e.which || e.button;
            isLeftClick = button === 1;
        }
        if (isLeftClick) {
            onSelect(option, e);
            this.setState({
                focusedIndex: index
            });
        }
    };

    render() {
        const {placeholder, disabled, clear, options, value} = this.props;
        const {inFocus, focusedIndex} = this.state;
        const {setPushPadRef, pushPadKeyDown, mouseDownOnItem} = this;

        const selectClasses = classNames('select', {
            'select--has-value': !!value
        });

        const listClasses = classNames('select__list', {
            'select__list--hidden': !inFocus
        });

        return (
            <div
                className={selectClasses}
                tabIndex={!disabled ? 1 : null}
                ref={setPushPadRef}
                onKeyDown={(e) => !disabled && pushPadKeyDown(e)}
                disabled={disabled}
            >
                <div
                    className={'select__selected'}
                    disabled={disabled}
                >
                    {
                        !value && (
                            <div className={'select__placeholder'}>
                                {placeholder}
                            </div>
                        )
                    }
                    {
                        value && value.label
                    }
                </div>
                <div className='select__icon'>
                    <InputS />
                </div>
                {
                    clear && (
                        <i
                            className={'select__clear'}
                            onClick={clear}
                        />
                    )
                }
                {
                    options.length > 0 && (
                        <ul
                            className={listClasses}
                        >
                            {
                                options.map((option, index) => {
                                    const itemClasses = classNames('select__item', {
                                        'select__item--focus': focusedIndex === index,
                                        'select__item--current': value && value.id === option.id
                                    });
                                    return (
                                        <li
                                            className={itemClasses}
                                            key={option.id}
                                            tabIndex={-1}
                                            onMouseDown={mouseDownOnItem(option, index)}
                                            onClick={(e) => {
                                                e.target.blur();
                                            }}
                                        >
                                            {option.label}
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    )
                }
            </div>
        );
    }
}

export default Select;
