import ChartActions from '../actions/ChartActions';
import {getTradeLaneIndexById} from '../selectors/chart';
import {granularityEnum} from '../../constants/granularity';
import {viewModeEnum} from '../../constants/viewMode';
import config, {CONFIG_INITIAL_STATE} from './ConfigReducer';

const INITIAL_STATE = {
    template: {
        comparison: {
            on: false
        },
        granularity: granularityEnum.week,
        view: viewModeEnum.chart
    },
    tradeLanes: [],
    config: CONFIG_INITIAL_STATE
};

function template (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ChartActions.SET_TEMPLATE: {
            return {
                ...state,
                template: action.template
            }
        }
        case ChartActions.CLEAR_TEMPLATE: {
            return {
                ...state,
                template: {
                    ...INITIAL_STATE.template
                }
            }
        }
        case ChartActions.EXTEND_TRADELANES: {
            const tradeLanes = [...action.tradeLanes];

            return {
                ...state,
                template: {
                    ...state.template,
                    tradeLanes: state.template.tradeLanes.map((tradeLane) => {
                        const tradeLineIndex = getTradeLaneIndexById(tradeLanes, tradeLane.id);
                        if (tradeLineIndex === -1) {
                            throw new Error(`Unknown tradeLane id: ${tradeLane.id}`);
                        }
                        const [fullTradeLane] = tradeLanes.splice(tradeLineIndex, 1);
                        return {
                            ...tradeLane,
                            name: fullTradeLane.name,
                            origin: fullTradeLane.origin,
                            destination: fullTradeLane.destination
                        }
                    })
                }
            }
        }
        case ChartActions.SET_TRADELANES: {
            return {
                ...state,
                tradeLanes: action.tradeLanes
            }
        }
        case ChartActions.EXTEND_MODEL_INSTANCES: {
            const {templateInstances} = action;
            let asOfDataPoint = state.template.asOfDataPoint;
            let endDataPoint = state.template.endDataPoint;
            let startDataPoint = state.template.startDataPoint;
            let startPoint = state.template.startPoint;
            let endPoint = state.template.endPoint;
            const tradeLanes = state.template.tradeLanes.map((tradeLane) => {
                const models = tradeLane.models.map((model) => {
                    const modelInstances = templateInstances
                        .filter((templateInstance) => {
                            return templateInstance.tradeLaneId === tradeLane.id
                                && templateInstance.modelId === model.id
                        }).map((templateInstance) => {
                            let {dataPoints} = templateInstance.modelInstance.timeSeries;
                            if (dataPoints) {
                                dataPoints = templateInstance.modelInstance.timeSeries.dataPoints.sort((a, b) => a.seqNumber - b.seqNumber);
                                templateInstance.modelInstance.timeSeries.dataPoints = dataPoints;
                            }
                            return templateInstance.modelInstance;
                        });
                    if (asOfDataPoint === null) {
                        asOfDataPoint = modelInstances[0].asOfDataPoint;
                    }
                    if (endDataPoint === null) {
                        endDataPoint = modelInstances[0].endDataPoint;
                    }
                    if (startDataPoint === null) {
                        startDataPoint = modelInstances[0].startDataPoint;
                    }
                    if (startPoint === null) {
                        startPoint = modelInstances[0].startDataPoint;
                    }
                    if (endPoint === null) {
                        endPoint = modelInstances[0].endDataPoint;
                    }
                    return {
                        ...model,
                        modelInstances
                    };
                });
                return {
                    ...tradeLane,
                    models
                }
            });

            return {
                ...state,
                template: {
                    ...state.template,
                    asOfDataPoint,
                    endDataPoint,
                    startDataPoint,
                    startPoint,
                    endPoint,
                    tradeLanes
                }
            }
        }
        case ChartActions.CLEAR_MODEL_INSTANCES: {
            return {
                ...state,
                template: {
                    ...state.template,
                    tradeLanes: state.template.tradeLanes.map((tradeLane) => {
                        const models = tradeLane.models.map((model) => {
                            return {...model, modelInstances: []};
                        });
                        return {...tradeLane, models};
                    })
                }
            }
        }
        case ChartActions.REPLACE_TEMPLATE_TRADELANE: {
            const tradeLanes = state.template.tradeLanes
                .filter((tradeLane) => tradeLane.id !== action.prevTradeLaneId);
            tradeLanes.push(action.newTradeLane);

            return {
                ...state,
                template: {
                    ...state.template,
                    tradeLanes
                }
            }
        }
        case ChartActions.CHANGE_TEMPLATE_GRANULARITY: {
            return {
                ...state,
                template: {
                    ...state.template,
                    startDataPoint: null,
                    endDataPoint: null,
                    startPoint: null,
                    endPoint: null,
                    asOfDataPoint: null,
                    granularity: action.granularity,
                    comparison: {
                        ...state.template.comparison,
                        on: false,
                        endDataPoint: null,
                        startDataPoint: null
                    }
                }
            }
        }
        case ChartActions.CHANGE_TEMPLATE_COMPARISON: {
            return {
                ...state,
                template: {
                    ...state.template,
                    comparison: {
                        ...state.template.comparison,
                        startDataPoint: state.template.startDataPoint - (state.template.endDataPoint - state.template.startDataPoint),
                        endDataPoint: state.template.startDataPoint - 1,
                        on: action.comparison
                    }
                }
            }
        }
        case ChartActions.CHANGE_TEMPLATE_VIEW_MODE: {
            return {
                ...state,
                template: {
                    ...state.template,
                    view: action.viewMode
                }
            }
        }
        case ChartActions.CHART_RESIZE: {
            if (state.template.comparison.on) {
                return {
                    ...state,
                    template: {
                        ...state.template,
                        startDataPoint: action.startDataPoint,
                        endDataPoint: action.endDataPoint,
                        asOfDataPoint: action.asOfDataPoint,
                        comparison: {
                            ...state.template.comparison,
                            startDataPoint: action.comparisonStartDataPoint,
                            endDataPoint: action.startDataPoint - 1
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    template: {
                        ...state.template,
                        startDataPoint: action.startDataPoint,
                        endDataPoint: action.endDataPoint,
                        asOfDataPoint: action.asOfDataPoint,
                        comparison: {
                            ...state.template.comparison
                        }
                    }
                }
            }

        }
        case ChartActions.SET_TEMPLATE_DATA: {
            return {
                ...state,
                template: {
                    ...state.template,
                    ...action.data
                }
            }
        }
        default: {
            state.config = config(state.config, action, state.template);
            return {...state};
        }
    }
}

export default template;
