/* @flow */

import {authHttpRequest} from '../utils/authAxios';
import ConfigActions from '../actions/ConfigActions';
import {
    getTemplateComparison,
    getTemplateGranularity,
    getTemplateStartDataPoint,
    getTemplateEndDataPoint,
    getComparisonStartDataPoint,
    getComparisonEndDataPoint,
    getAsOfDataPoint
} from '../selectors/chart';
import qs from 'qs';
import {viewModeEnum} from "../../constants/viewMode";
import {granularityEnum} from "../../constants/granularity";
import {showLoading, resetLoading, hideLoading} from '../../components/react-redux-loading-bar/reducer'

class ChartActions {
    static SET_TEMPLATE = 'SET_TEMPLATE';
    static CLEAR_TEMPLATE = 'CLEAR_TEMPLATE';
    static SAVE_TEMPLATE = 'SAVE_TEMPLATE';
    static EXTEND_TRADELANES = 'EXTEND_TRADELANES';
    static EXTEND_MODEL_INSTANCES = 'EXTEND_MODEL_INSTANCES';
    static CLEAR_MODEL_INSTANCES = 'CLEAR_MODEL_INSTANCES';
    static SET_TRADELANES = 'SET_TRADELANES';
    static REPLACE_TEMPLATE_TRADELANE = 'REPLACE_TEMPLATE_TRADELANE';
    static CHANGE_TEMPLATE_GRANULARITY = 'CHANGE_TEMPLATE_GRANULARITY';
    static CHANGE_TEMPLATE_COMPARISON = 'CHANGE_TEMPLATE_COMPARISON';
    static CHANGE_TEMPLATE_VIEW_MODE = 'CHANGE_TEMPLATE_VIEW_MODE';
    static CHART_RESIZE = 'CHART_RESIZE';
    static SET_TEMPLATE_DATA = 'SET_TEMPLATE_DATA';

    static saveTemplate(id: number) {

    }

    static getTemplateById(id: number) {
        return async function (dispatch, getState) {
            try {
                dispatch(showLoading());
                dispatch({
                    type: ChartActions.CLEAR_TEMPLATE
                });
                const templateData = await authHttpRequest({
                    url: `/api/template/${id}`,
                    timeout: 2000,
                    method: 'get',
                    responseType: 'json'
                });
                if (!templateData.data) {
                    throw new Error(`Template id ${id} does not exist`);
                }
                const template = templateData.data.data;
                dispatch({
                    type: ChartActions.SET_TEMPLATE,
                    template: {
                        ...template,
                        tradeLanes: template.tradeLanes.tradeLanes || []
                    }
                });
                const tradeLanesData = await authHttpRequest({
                    url: `/api/tradelane`,
                    timeout: 2000,
                    method: 'GET',
                    responseType: 'json'
                });
                const tradeLanes = tradeLanesData.data.data || [];
                dispatch({
                    type: ChartActions.EXTEND_TRADELANES,
                    tradeLanes
                });
                dispatch({
                    type: ChartActions.SET_TRADELANES,
                    tradeLanes
                });
                dispatch(hideLoading());
                await ChartActions._extendModelInstances()(dispatch, getState);
            } catch (e) {
                dispatch(resetLoading());
                console.error(e);
            }


            /*const models = [];
w
            modelsResult.forEach((modelData) => {
                if (modelData.data.data.timeSeries.category === 'R') {
                    modelData.data.data.timeSeries.dataPoints.forEach((point) => {
                        models.push({
                            rate: point.value,
                            date: point.refDate
                        });
                    })
                } else if (modelData.data.data.timeSeries.category === 'V') {
                    modelData.data.data.timeSeries.dataPoints.forEach((point) => {
                        models.push({
                            volume: point.value,
                            date: point.refDate
                        })
                    })
                }
            });*/
        }
    }

    static _extendModelInstances() {
        return async function (dispatch, getState) {
            try {
                const modelPromises = [];
                const granularity = getTemplateGranularity(getState());
                const comparison = getTemplateComparison(getState());
                const startDataPoint = getState().chart.template.startDataPoint;
                const endDataPoint = getState().chart.template.endDataPoint;
                const asOfDataPoint = getState().chart.template.asOfDataPoint;
                const comparisonStartDataPoint = getState().chart.template.comparison.startDataPoint;
                const comparisonEndDataPoint = getState().chart.template.comparison.endDataPoint;

                // dispatch({
                //     type: ConfigActions.CLEAR_CHART_CONFIG
                // });

                dispatch(showLoading());

                getState().chart.template.tradeLanes.forEach((tradeLane) => {
                    tradeLane.models.forEach((model) => {
                        const queryParams = {
                            granularity,
                            comparison,
                            startDataPoint,
                            endDataPoint,
                            asOfDataPoint
                        };
                        if (comparison) {
                            queryParams.comparisonStartDataPoint = comparisonStartDataPoint;
                            queryParams.comparisonEndDataPoint = comparisonEndDataPoint;
                        }
                        modelPromises.push(authHttpRequest({
                            url: `/api/instance/${model.id}?${qs.stringify(queryParams)}`,
                            timeout: 20000,
                            method: 'get',
                            responseType: 'json'
                        }).then((response) => {
                            const modelInstanceFull = response.data.data;
                            return {
                                tradeLaneId: tradeLane.id,
                                modelId: model.id,
                                modelInstance: modelInstanceFull
                            };
                        }));
                    })
                });

                const templateInstances = await Promise.all(modelPromises);

                dispatch(hideLoading());

                dispatch({
                    type: ChartActions.EXTEND_MODEL_INSTANCES,
                    templateInstances
                });

                if (getState().chart.template.view === viewModeEnum.chart) {
                    dispatch(ConfigActions.calculateChart());
                }

            } catch (e) {
                dispatch(resetLoading());
                dispatch({
                    type: ChartActions.CLEAR_MODEL_INSTANCES
                });
                dispatch({
                    type: ConfigActions.CLEAR_CHART_CONFIG
                });
                const data = {};
                const asOfDataPoint = 0;
                if (getState().chart.template.granularity === granularityEnum.week) {
                    data.startPoint = asOfDataPoint - 100;
                    data.endPoint = asOfDataPoint + 100;
                    data.startDataPoint = asOfDataPoint - 50;
                    data.endDataPoint = asOfDataPoint + 50;
                } else if(getState().chart.template.granularity === granularityEnum.month) {
                    data.startPoint = asOfDataPoint - 20;
                    data.endPoint = asOfDataPoint + 20;
                    data.startDataPoint = asOfDataPoint - 10;
                    data.endDataPoint = asOfDataPoint + 10;
                } else if(getState().chart.template.granularity === granularityEnum.quarter) {
                    data.startPoint = asOfDataPoint - 8;
                    data.endPoint = asOfDataPoint + 8;
                    data.startDataPoint = asOfDataPoint - 2;
                    data.endDataPoint = asOfDataPoint + 2;
                }
                dispatch({
                    type: ChartActions.SET_TEMPLATE_DATA,
                    data
                })
            }
        };
    }

    static changeGranularity(granularity: 'W' | 'M' | 'Q') {
        return async function (dispatch, getState) {
            dispatch({
                type: ChartActions.CHANGE_TEMPLATE_GRANULARITY,
                granularity
            });

            ChartActions._extendModelInstances()(dispatch, getState);
        };
    }

    static toggleComparison(comparison: boolean) {
        return function (dispatch, getState) {
            let _comparison = comparison;

            if (typeof _comparison !== 'boolean') {
                _comparison = !getTemplateComparison(getState());
            }

            dispatch({
                type: ChartActions.CHANGE_TEMPLATE_COMPARISON,
                comparison: _comparison
            })
        };
    }

    static changeViewMode(viewMode: 'TABLE' | 'CHART') {
        return {
            type: ChartActions.CHANGE_TEMPLATE_VIEW_MODE,
            viewMode
        }
    }

    static replaceTradeLane (prevTradeLaneId: number, newTradeLaneId: number) {
        return async function (dispatch, getState) {
            const newTradeLaneData = await authHttpRequest({
                url: `/api/tradelane/${newTradeLaneId}`,
                timeout: 2000,
                method: 'GET',
                responseType: 'json'
            });
            const newTradeLane = newTradeLaneData.data.data;
            dispatch({
                type: ChartActions.REPLACE_TEMPLATE_TRADELANE,
                newTradeLane,
                prevTradeLaneId
            });
            ChartActions._extendModelInstances()(dispatch, getState);
        };
    }

    static resize (startDataPoint: number, asOfDataPoint: number, endDataPoint: number, comparisonStartDataPoint: number) {
        return async function (dispatch, getState) {
            dispatch({
                type: ChartActions.CHART_RESIZE,
                startDataPoint,
                endDataPoint,
                asOfDataPoint,
                comparisonStartDataPoint
            });
            await ChartActions._extendModelInstances()(dispatch, getState);
        };
    }
}

export default ChartActions;
