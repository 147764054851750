/* @flow */

type Props = {
    id: string | number,
    value: any,
    label: string
};

class Option {
    constructor(props: Props) {
        this.id = props.id;
        this.value = props.value;
        this.label = props.label;
    }
}

export default Option;
