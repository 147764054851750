/* @flow */
import React from 'react';

type Props = {
    width: string,
    height: string,
    className: string
};

export default function (props: Props) {
    const width = props.width || '19px';
    const height = props.height || '19px';
    const className = props.className || '';

    return (
        <svg width={width} height={height} viewBox='0 0 19 19' version='1.1' xmlns='http://www.w3.org/2000/svg' className={className}>
            <g id='Main' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g id='Rate+Volume-Comparison' transform='translate(-511.000000, -141.000000)' fill='#0A1E6E'
                   fillRule='nonzero'>
                    <g id='config' transform='translate(415.000000, 141.000000)'>
                        <g id='Group' transform='translate(96.000000, 0.000000)'>
                            <g id='Group-7'>
                                <path
                                    d='M8,5 L8,9 L7,9 L7,5 L5,5 L5,11 L4,11 L4,5 L2,5 L2,14 L16,14 L16,5 L14,5 L14,9 L13,9 L13,5 L11,5 L11,11 L10,11 L10,5 L8,5 Z M1,4 L17,4 L17,15 L1,15 L1,4 Z'
                                    id='Shape'></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
