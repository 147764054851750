import React, {Component} from 'react';
import ReactEcharts from "echarts-for-react";
import {connect} from 'react-redux';
import {getChartConfig} from '../../../../services/selectors/chart';
import './ChartContainer.scss';

class Chart extends Component {

    static chartStyle = {
        maxHeight: '800px',
        height: '100%'
    };

    render() {
        return (
            <div className='chart-container'>
                <ReactEcharts
                    option={this.props.config}
                    style={Chart.chartStyle}
                    notMerge={true}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        config: getChartConfig(state)
    }
}

export default connect(mapStateToProps)(Chart);
