import Option from '../../utils/Option';
import {categoryEnum} from '../../constants/category';

export function getTradeLaneIndexById(tradeLanes, id) {
    let index = -1;
    tradeLanes.forEach((tradeLane, _index) => {
        if (tradeLane.id === id) {
            index = _index;
        }
    });
    return index;
}

export function getTradeLanes(state) {
    return state.chart.tradeLanes
        ? state.chart.tradeLanes
        : [];
}

export function getTradeLanesOptions(state) {
    return getTradeLanes(state).map((tradeLane) => {
        let label = '';

        if (tradeLane.origin && tradeLane.destination) {
            label = `${tradeLane.origin.name} - ${tradeLane.destination.name}`
        }

        return new Option({
            id: tradeLane.id,
            value: tradeLane,
            label
        })
    })
}

export function getTemplateTradeLanes(state) {
    return state.chart.template.tradeLanes || [];
}

export function getTemplateGranularity(state) {
    return state.chart.template.granularity;
}

export function getTemplateComparison(state) {
    return state.chart.template.comparison.on;
}

export function getComparisonStartDataPoint(state) {
    return state.chart.template.comparison.startDataPoint || 0;
}

export function getComparisonEndDataPoint(state) {
    return state.chart.template.comparison.endDataPoint || 0;
}

export function getAsOfDataPoint(state) {
    return state.chart.template.asOfDataPoint || 0;
}

export function getTemplateViewMode(state) {
    return state.chart.template.view;
}

export function getTemplateStartDataPoint(state) {
    return state.chart.template.startDataPoint || 0;
}

export function getTemplateEndDataPoint(state) {
    return state.chart.template.endDataPoint || 0;
}

export function getTemplateLeftPoint(state) {
    let minPoint = 0;

    if (state.chart.template.tradeLanes) {
        state.chart.template.tradeLanes.forEach((tradeLane) => {
            if (tradeLane.models) {
                tradeLane.models.forEach((model) => {
                    if (model.modelInstances) {
                        model.modelInstances.forEach((modelInstance) => {
                            if (!modelInstance.timeSeries) {
                                return;
                            }
                            if (modelInstance.timeSeries.firstPoint && modelInstance.timeSeries.firstPoint < minPoint) {
                                minPoint = modelInstance.timeSeries.firstPoint;
                            }
                        })
                    }
                })
            }
        });
    }

    if (minPoint !== 0) {
        return minPoint;
    }
    return state.chart.template.startPoint || 0;
}

export function getTemplateRightPoint(state) {
    let maxPoint = 0;

    if (state.chart.template.tradeLanes) {
        state.chart.template.tradeLanes.forEach((tradeLane) => {
            if (tradeLane.models) {
                tradeLane.models.forEach((model) => {
                    if (model.modelInstances) {
                        model.modelInstances.forEach((modelInstance) => {
                            if (modelInstance.timeSeries && modelInstance.timeSeries.lastPoint && modelInstance.timeSeries.lastPoint > maxPoint) {
                                maxPoint = modelInstance.timeSeries.lastPoint;
                            }
                            if (modelInstance.forecast && modelInstance.forecast.lastPoint && modelInstance.forecast.lastPoint > maxPoint) {
                                maxPoint = modelInstance.forecast.lastPoint;
                            }
                        })
                    }
                })
            }
        });
    }

    if (maxPoint !== 0) {
        return maxPoint;
    }
    return state.chart.template.endPoint || 0;
}

export function getChartConfig(state) {
    return state.chart.config;
}

export function getTradeLaneRows(state) {
    const optionalCategories = [
        categoryEnum.volume,
        categoryEnum.rate
    ];
    const tradeLanes = [];
    /*
        tradeLanes: [
            {
                name,
                columns: [
                    {
                        rate,
                        volume,
                        date
                    }
                ]
            }
        ]
     */
    state.chart.template.tradeLanes.forEach((tradeLane) => {
        const existedCategories = optionalCategories.reduce((result, category) => {
            const categoryExist = tradeLane.models.some((model) =>
                model.modelInstances.some((modelInstance) =>
                    modelInstance.timeSeries.category === category));
            result[category] = categoryExist;
            return result;
        }, {});

        const columnTradeLane = {
            id: tradeLane.id,
            name: tradeLane.name,
            existedCategories,
            columns: []
        };

        tradeLane.models.forEach((model) => {
            model.modelInstances.forEach((modelInstance) => {
                modelInstance.timeSeries.dataPoints.forEach((dataPoint) => {
                    if (modelInstance.timeSeries.category === categoryEnum.rate) {
                        columnTradeLane.columns.push({
                            id: dataPoint.id,
                            date: dataPoint.refDate,
                            rate: dataPoint.value
                        });
                    } else {
                        columnTradeLane.columns.push({
                            id: dataPoint.id,
                            date: dataPoint.refDate,
                            volume: dataPoint.value
                        });
                    }
                });
            })
        });
        tradeLanes.push(columnTradeLane);
    });

    return tradeLanes;
}

export function haveTimeSeriesDataPoints(state) {
    return state.chart.template.tradeLanes &&
        state.chart.template.tradeLanes.some((tradeLane) =>
            tradeLane.models && tradeLane.models.some((model) =>
                model && model.modelInstances && model.modelInstances.length > 0));
}
