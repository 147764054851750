import { combineReducers } from 'redux';
import chart from './ChartReducer';
import templates from './TemplatesReducer';
import recentCharts from './RecentReducers';
import bookmarks from './BookmarksReducers';
import {loadingBarReducer} from '../../components/react-redux-loading-bar/reducer';

const rootReducer = combineReducers({
    chart,
    dashboard: combineReducers({
        templates,
        recentCharts,
        bookmarks
    }),
    loadingBar: loadingBarReducer
});

export default rootReducer;
