/* @flow */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Range, createSliderWithTooltip} from 'rc-slider';
import 'rc-slider/assets/index.css';
import './TimeControl.scss';
import {
    getTemplateLeftPoint,
    getTemplateRightPoint,
    getTemplateStartDataPoint,
    getTemplateEndDataPoint,
    getTemplateGranularity,
    getTemplateComparison,
    getComparisonStartDataPoint,
    getAsOfDataPoint
} from '../../../../services/selectors/chart';
import {
    granularityEnum
} from '../../../../constants/granularity';
import ChartActions from '../../../../services/actions/ChartActions';
import {
    getSequencePointMoment,
    getDateBySequenceNumber
} from '../../../../utils/BL';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

const RangeWithTooltip = createSliderWithTooltip(Range);

function mapStateToProps(state) {
    return {
        comparison: getTemplateComparison(state),
        axisStart: getTemplateLeftPoint(state),
        comparisonStartPoint: getComparisonStartDataPoint(state),
        startPoint: getTemplateStartDataPoint(state),
        asOfDataPoint: getAsOfDataPoint(state),
        endPoint: getTemplateEndDataPoint(state),
        granularity: getTemplateGranularity(state),
        axisEnd: getTemplateRightPoint(state)
    }
}

const tickTextStyle = {

};

const bigTickTextStyle = Object.assign(tickTextStyle, {
    // 'width': '50px',
    // 'top': '-2px',
    // 'textAlign': 'center',
    // 'whiteSpace': 'nowrap',
    // 'textIndent': '7px'
});

function getDateByPoint(currentPoint, granularity, eraStart) {
    switch (granularity) {
        case granularityEnum.week:
            return moment(eraStart).add(currentPoint, 'week').format('YYYY');
        case granularityEnum.month:
            return moment(eraStart).add(currentPoint, 'month').format('YYYY');
        case granularityEnum.quarter:
            return moment(eraStart).add(currentPoint * 3, 'month').format('YYYY');
    }
}

class TimeControl extends Component {
    static getDerivedStateFromProps(props, state) {
        const {
            comparison,
            axisStart,
            comparisonStartPoint,
            startPoint,
            asOfDataPoint,
            endPoint,
            granularity,
            axisEnd
        } = props;
        //region recalculate axis

        if (!endPoint) {
            return;
        }

        if (axisStart !== state.min || axisEnd !== state.max || granularity !== state.granularity || comparison !== state.comparison) {
            const marks = {};
            let currentPoint = axisStart;

            switch (granularity) {
                case granularityEnum.week: {
                    while (currentPoint <= axisEnd) {
                        let currentMoment = moment({year: 2000}).add(currentPoint, 'week');
                        let prevMoment = moment({year: 2000}).add(currentPoint - 1, 'week');
                        if (prevMoment.get('year') < currentMoment.get('year')) {
                            marks[currentPoint] = {
                                label: getDateByPoint(currentPoint, granularity, {year: 2000}),
                                style: bigTickTextStyle
                            };
                        } else if (
                            prevMoment.get('month') === 2 && currentMoment.get('month') === 3
                            || prevMoment.get('month') === 5 && currentMoment.get('month') === 6
                            || prevMoment.get('month') === 8 && currentMoment.get('month') === 9
                        ) {
                            marks[currentPoint] = {
                                style: tickTextStyle
                            };
                        }

                        currentPoint++;
                    }
                    break;
                }
                case granularityEnum.month: {
                    while (currentPoint <= axisEnd) {
                        let currentMoment = moment({year: 2000}).add(currentPoint, 'month');
                        let prevMoment = moment({year: 2000}).add(currentPoint - 1, 'month');
                        if (prevMoment.get('year') < currentMoment.get('year')) {
                            marks[currentPoint] = {
                                label: getDateByPoint(currentPoint, granularity, {year: 2000}),
                                style: bigTickTextStyle
                            };
                        } else if (
                            prevMoment.get('month') === 2 && currentMoment.get('month') === 3
                            || prevMoment.get('month') === 5 && currentMoment.get('month') === 6
                            || prevMoment.get('month') === 8 && currentMoment.get('month') === 9
                        ) {
                            marks[currentPoint] = {
                                style: tickTextStyle
                            };
                        }

                        currentPoint++;
                    }
                    break;
                }
                case granularityEnum.quarter: {
                    while (currentPoint <= axisEnd) {
                        let currentMoment = moment({year: 2000}).add(currentPoint, 'year');
                        let prevMoment = moment({year: 2000}).add(currentPoint - 1, 'year');
                        if (prevMoment.get('year') < currentMoment.get('year')) {
                            marks[currentPoint] = {
                                label: getDateByPoint(currentPoint, granularity, {year: 2000}),
                                style: bigTickTextStyle
                            };
                        } else if (
                            prevMoment.get('month') === 2 && currentMoment.get('month') === 3
                            || prevMoment.get('month') === 5 && currentMoment.get('month') === 6
                            || prevMoment.get('month') === 8 && currentMoment.get('month') === 9
                        ) {
                            marks[currentPoint] = {
                                style: tickTextStyle
                            };
                        }

                        currentPoint++;
                    }
                    break;
                }
            }
            if (comparison) {
                return {
                    granularity,
                    comparison,
                    value: [
                        comparisonStartPoint,
                        startPoint,
                        asOfDataPoint,
                        endPoint
                    ],
                    marks,
                    min: axisStart,
                    max: axisEnd
                }
            }
            return {
                granularity,
                comparison,
                value: [
                    startPoint,
                    asOfDataPoint,
                    endPoint
                ],
                marks,
                min: axisStart,
                max: axisEnd
            }
        }
        //endregion

        return state;
    }

    constructor(props) {
        super(props);

        this.state = {
            granularity: null,
            comparison: null,
            value: [],
            marks: [],
            min: 0,
            max: 1
        };

        this.handleChange = throttle(this.handleChange.bind(this), 20);
        this.onKeyUp = debounce(this.onKeyUp.bind(this), 20);
    }

    onKeyUp(values: Array) {
        if (this.state.comparison) {
            const [
                comparisonStartPoint,
                startPoint,
                asOfDataPoint,
                endPoint
            ] = values;
            this.props.dispatch(ChartActions.resize(
                startPoint,
                asOfDataPoint,
                endPoint,
                comparisonStartPoint
            ));
        } else {
            const [
                startPoint,
                asOfDataPoint,
                endPoint
            ] = values;
            this.props.dispatch(ChartActions.resize(
                startPoint,
                asOfDataPoint,
                endPoint
            ));
        }

    }

    handleChange(value) {
        if (this.state.comparison) {
            const [comparisonStart, start, asOfDate, end] = value;
            const {value: [prevComparisonStart, prevStart, prevAsOfDate, prevEnd], min, max} = this.state;
            if (prevEnd !== end) { //--*----*--*--O--
                const nextComparisonStart = prevStart - (end - prevStart);
                if (nextComparisonStart < min) {
                    return;
                }
                if (end < (asOfDate + 5)) {
                    return;
                }
                this.setState({value: [nextComparisonStart, prevStart, prevAsOfDate, end]});
            } else if (prevAsOfDate !== prevAsOfDate) { //--*----*--O--*--
                return;
            } else if (prevStart !== start) { //--*----O--*--*--
                const nextComparisonStart = start - (prevEnd - start);
                if (nextComparisonStart < min) {
                    return;
                }
                if (start > (asOfDate - 5)) {
                    return;
                }
                this.setState({value: [nextComparisonStart, start, prevAsOfDate, prevEnd]});
            } else if (prevComparisonStart !== comparisonStart) { //--O----*--*--*--
                return;
            }
        } else {
            const [start, asOfDate, end] = value;
            const {value: [prevStart, prevAsOfDate, prevEnd], min, max} = this.state;
            if (prevEnd !== end) { //--*--*--O--
                if (end < (asOfDate + 5)) {
                    return;
                }
                this.setState({value: [prevStart, prevAsOfDate, end]});
            } else if (prevAsOfDate !== prevAsOfDate) { //--*--O--*--
                return;
            } else if (prevStart !== start) { //--O--*--*--
                if (start > (asOfDate - 5)) {
                    return;
                }
                this.setState({value: [start, prevAsOfDate, prevEnd]});
            }
        }
    }

    tipFormatter = (value) => {
        return getDateBySequenceNumber({
            granularity: this.props.granularity,
            seqNumber: value,
            startSequenceDate: {year: 2000}
        })
    };

    render() {
        const {
            onKeyUp,
            handleChange,
            tipFormatter
        } = this;

        const {
            min,
            max,
            value,
            marks,
            comparison
        } = this.state;

        const handleStyle = comparison
            ? [
                {backgroundColor: '#878C96', border: 'solid 2px #878C96'},
                {backgroundColor: '#0A1E6E', border: 'solid 2px #0A1E6E'},
                {
                    'border': '1px solid rgb(135, 140, 150)',
                    'height': '40px',
                    'borderRadius': '0',
                    'width': '0',
                    'top': '-2px',
                    'marginLeft': '0'
                },
                {backgroundColor: '#0A1E6E', border: 'solid 2px #0A1E6E'}
            ]
            : [
                {backgroundColor: '#0A1E6E', border: 'solid 2px #0A1E6E'},
                {
                    'border': '1px solid rgb(135, 140, 150)',
                    'height': '40px',
                    'borderRadius': '0',
                    'width': '0',
                    'top': '-2px',
                    'marginLeft': '0'
                },
                {backgroundColor: '#0A1E6E', border: 'solid 2px #0A1E6E'}
            ];

        const trackStyle = comparison
            ? [
                {backgroundColor: '#878C96'},
                {backgroundColor: '#0A1E6E'},
                {backgroundColor: '#0A1E6E'},
            ]
            : [
                {backgroundColor: '#0A1E6E'},
                {backgroundColor: '#0A1E6E'},
            ];

        return (
            <RangeWithTooltip
                min={min}
                max={max}
                value={value}
                marks={marks}
                onChange={handleChange}
                step={1}
                pushable={3}
                onAfterChange={onKeyUp}
                tipFormatter={tipFormatter}
                trackStyle={trackStyle}
                handleStyle={handleStyle}
                railStyle={{backgroundColor: '#C8CDD2'}}
            />
        )
    }
}

export default connect(mapStateToProps)(TimeControl);
