import moment from 'moment';
import {granularityEnum} from '../constants/granularity';

export function getDateBySequenceNumber({granularity, seqNumber, startSequenceDate='2000-01-01'}) {
    switch (granularity) {
        case granularityEnum.week:
            return moment(startSequenceDate).add(seqNumber, 'week').format('DD MMM YYYY');
        case granularityEnum.month:
            return moment(startSequenceDate).add(seqNumber, 'month').format('MMM YYYY');
        case granularityEnum.quarter:
            return moment(startSequenceDate).add(seqNumber * 3, 'month').format('MMM YYYY');
    }
}

export function getSequencePointMoment({point, granularity, startingDate}) {
    switch (granularity) {
        case granularityEnum.week:
            return moment(startingDate).add(point, 'week');
        case granularityEnum.month:
            return moment(startingDate).add(point, 'month');
        case granularityEnum.quarter:
            return moment(startingDate).add(Math.round(point * 3), 'month')
    }
}
