import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import { connect } from 'react-redux';
import { getChartConfig } from './chartConfig'

class BookmarkPreview extends PureComponent {
    static defaultProps = {
        chart: {}
    };

    state = {
        conf: {}
    };

    componentWillMount() {
        const {chart} = this.props;
        getChartConfig(chart).then(data => this.setState({conf: data}));
    }

    render() {
        const {conf} = this.state;
        return (
            <ReactEcharts
                option={conf}
                style={{height: '100%'}}
                notMerge={true}
            />

        )
    }
}

export default connect()(BookmarkPreview);
