import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';

import Logo from '../../icons/Logo';
import { BookmarksList, FunctionTemplateList, RecentChartsList } from './components';
import { TemplatesActions, ResentActions, BookmarksActions } from '../../services/actions';
import { RECENT_ORDER } from '../../services/actions/ResentActions';

import './Dashboard.scss';

const mapStateToProps = (state) => {
    return {
        ...state.dashboard
    }
};

const mapReducersToProps = {
    getTemplatesList: TemplatesActions.getTemplatesList,
    getRecentList: ResentActions.getRecentList,
    filterRecentListByName: ResentActions.filterByName,
    changeRecentOrder: ResentActions.changeOrder,
    resetRecentFilters: ResentActions.reset,
    getBookmarksList: BookmarksActions.getBookmarkstList,
    addToBookmarks: BookmarksActions.addToBookmarks,
    removeFromBookmarks: BookmarksActions.removeFromBookmarks
};

class Dashboard extends Component {
    static defaultProps = {
        bookmarks: [],
        templates: [],
        recentCharts: []
    };

    constructor(props) {
        super(props);
        this.state = {
            showTemplates: false,
            recentOrder: RECENT_ORDER.DESC
        };

        this.filterResertChart = debounce(this.filterResertChart.bind(this), 400);
    }

    componentDidMount() {
        if (!this.props.templates.isFetched) {
            this.props.getTemplatesList();
        }
        if (!this.props.recentCharts.isFetched) {
            this.props.getRecentList();
        }
        if (!this.props.bookmarks.isFetched) {
            this.props.getBookmarksList();
        }
    }

    componentWillUnmount() {
        this.props.resetRecentFilters();
    }

    toogleTemplatesBlock = () => {
        this.setState({
            showTemplates: !this.state.showTemplates
        });
    };

    filterResertChart(searchText) {
        this.props.filterRecentListByName(searchText);
    }

    changeRecentOrder = () => {
        const newOrder = this.props.recentCharts.order === RECENT_ORDER.ASC ? RECENT_ORDER.DESC : RECENT_ORDER.ASC;
        this.props.changeRecentOrder(newOrder);
    };

    addToBookmarks = (chart) => {
        const {bookmarks: {list: bookmarksList}} = this.props;
        if (bookmarksList.length >= 2) {
            return;
        }

        this.props.addToBookmarks(chart);
    };

    removeFromBookmarks = (chart) => {
        this.props.removeFromBookmarks(chart);
    };

    render() {
        const {
            templates: {list: templates},
            recentCharts: {filteredList: recentCharts, order: recentOrder},
            bookmarks: {list: bookmarks}
        } = this.props;

        const {showTemplates} = this.state;
        const showBookmarks = bookmarks.length > 0;

        const orderClass = classNames(
            'recent-filters__order', {
                'recent-filters__order--asc': recentOrder === RECENT_ORDER.ASC,
                'recent-filters__order--desc': recentOrder === RECENT_ORDER.DESC
            });

        return (
            <div className='app app--without-menu'>
                <div className='app__top'>
                    <div className='top'>
                        <div className='top__logo'>
                            <Link to='/'><Logo/></Link>
                        </div>
                        <div className='top__user'>
                            <img
                                className='top__user-icon'
                                src='http://icons.iconarchive.com/icons/papirus-team/papirus-apps/256/google-icon.png'
                                alt='logo'
                            />
                            <div className='top__user-name'>
                                Ray Cooper
                            </div>
                        </div>
                    </div>
                </div>
                <div className='app__content dashboard'>
                    {showBookmarks && <div className='dashboard__block dashboard__bookmarks'>
                        <BookmarksList
                            bookmarks={bookmarks}
                            removeFromBookmarks={this.removeFromBookmarks}
                            className='dashboard__block'></BookmarksList>
                    </div>}

                    {showTemplates &&
                    <div className='dashboard__block dashboard__templates'>
                        <div className='dashboard__block__header'>
                            <h2>TME functions</h2>
                            <button onClick={this.toogleTemplatesBlock}
                                    type='button'
                                    title='Hide TME functions'
                                    className='hide-templates-btn'></button>
                        </div>
                        <FunctionTemplateList templates={templates}></FunctionTemplateList>
                    </div>
                    }

                    <div className='dashboard__block dashboard__recent'>
                        <div className='dashboard__block__header'>
                            <h2>Recent charts</h2>
                            <div className='recent-filters'>
                                <button className={orderClass} onClick={this.changeRecentOrder}>order by <b>LAST
                                    CHANGE</b></button>
                                <input className='recent-filters__input'
                                       type='text'
                                       placeholder='Search'
                                       onKeyUp={(e) => this.filterResertChart(e.target.value)}
                                />
                                {!showTemplates &&
                                <button className='show-templates-btn'
                                        type='button'
                                        onClick={this.toogleTemplatesBlock}>Show TME functions</button>}
                            </div>
                        </div>
                        <RecentChartsList
                            charts={recentCharts}
                            addToBookmarks={this.addToBookmarks}
                            removeFromBookmarks={this.removeFromBookmarks}
                        ></RecentChartsList>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapReducersToProps)(Dashboard);
