/* @flow */

import React, {Component} from 'react';
import classNames from 'classnames';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getTradeLaneRows} from '../../../../services/selectors/chart';
import './table-container.scss';
import {categoryEnum} from "../../../../constants/category";

type Props = {
    tradeLaneRows: Array
}

function mapStateToProps(state) {
    return {
        tradeLaneRows: getTradeLaneRows(state)
    }
}

class TradeTable extends Component<Props> {
    render() {

        const {tradeLaneRows} = this.props;

        return (
            <React.Fragment>
                <div className='grid grid--header'>
                    {
                        tradeLaneRows.map((tradeLaneRow) => (
                            <div
                                key={tradeLaneRow.id}
                                className='grid__column'
                            >
                                <div className='grid__row'>
                                    <div
                                        className='grid__column grid__column--10 grid__border-right'
                                        style={{
                                            justifyContent: 'flex-end'
                                        }}
                                    >
                                        <div className='grid__cell grid__cell--left'>
                                            Date
                                        </div>
                                    </div>
                                    <div className='grid__column grid__column--90'>
                                        <div className='grid__cell grid__cell--center grid__border-bottom'>
                                            {tradeLaneRow.name}
                                        </div>
                                        <div className='grid__row'>
                                            <div className='grid__cell grid__cell--100'>
                                                Rate
                                            </div>
                                            {/*                                        <div className='grid__cell grid__cell--80'>
                                            Volume
                                        </div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className='grid'>
                    {
                        tradeLaneRows.map((tradeLaneRow) => (
                            <div
                                key={tradeLaneRow.id}
                                className='grid__column'
                            >
                                {
                                    tradeLaneRow.columns.map((tradeLaneColumn) => (
                                        <div className='grid__row grid__row--with-color'>
                                            <div className='grid__cell grid__cell--left grid__cell--bold grid__cell--10 grid__border-right'>
                                                {tradeLaneColumn.date}
                                            </div>
                                            {
                                                !!tradeLaneRow.existedCategories[categoryEnum.rate] && (
                                                    <div className='grid__cell grid__cell--90'>
                                                        {tradeLaneColumn.rate}
                                                    </div>
                                                )
                                            }
                                            {
                                                !!tradeLaneRow.existedCategories[categoryEnum.volume] && (
                                                    <div className='grid__cell'>
                                                        {tradeLaneColumn.volume}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(TradeTable));
