import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import './FunctionTemplateList.scss';
import preview from '../../../../images/templates/chart.png';


class FunctionTemplateList extends PureComponent {
    static defaultProps = {
        templates: []
    };

    render() {
        const {templates} = this.props;
        const templatesList = templates.map((template, idx) => (

            <li key={idx}
                className='templates-list__item template-preview'>
                <Link to={`chart/${template.id}`} className='template-preview__link'>
                    <img className='template-preview__preview' alt='' src={preview}/>
                    <div className='template-preview__name'>
                        {template.name}
                    </div>
                </Link>
            </li>)
        );

        return (<ul className='templates-list'>{templatesList}</ul>);
    }
}

export default FunctionTemplateList;
