/* @flow */
import React from 'react';

type Props = {
    width: string,
    height: string,
    className: string
};

export default function (props: Props) {
    const width = props.width || '24px';
    const height = props.height || '24px';
    const className = props.className || '';

    return (
        <svg width={width} height={height} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' className={className}>
            <defs>
                <rect id='path-1' x='0' y='0' width='351' height='740'></rect>
            </defs>
            <g id='Main' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g id='Rate+Volume' transform='translate(-163.000000, -160.000000)'>
                    <g id='Aside' transform='translate(0.000000, 60.000000)'>
                        <g transform='translate(0.000000, 100.000000)' id='Title'>
                            <g transform='translate(34.000000, 0.000000)'>
                                <text id='Trade-lane-#1' fontFamily='DBSans-Bold, DB Sans' fontSize='16'
                                      fontWeight='bold' fill='#282D37'>
                                    <tspan x='16' y='18'>Trade lane #1</tspan>
                                </text>
                                <g id='Icons/Edit' transform='translate(129.000000, 0.000000)' stroke='#878C96'
                                   strokeWidth='2'>
                                    <polygon id='Path-2' points='16.25 5 19 7.75 9.75 17 6.25 17.75 7 14.25'></polygon>
                                    <path d='M13.75,6.75 L17.359375,10.359375' id='Line' strokeLinecap='square'></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
