import { authHttpRequest } from '../utils/authAxios';

class TemplatesActions {
    static FETCH_TEMPLATES_REQUEST = 'FETCH_TEMPLATES_REQUEST';
    static FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
    static FETCH_TEMPLATES_ERROR = 'FETCH_TEMPLATES_ERROR';

    static getTemplatesList() {
        return dispatch => {
            dispatch({
                type: TemplatesActions.FETCH_TEMPLATES_REQUEST
            });
            return authHttpRequest({
                url: `/api/template/functionTemplate`,
                timeout: 2000,
                method: 'GET',
                responseType: 'json'
            }).then(responce => {
                dispatch({
                    type: TemplatesActions.FETCH_TEMPLATES_SUCCESS,
                    payload: responce.data.data
                });
            }).catch(e => {
                dispatch({
                    type: TemplatesActions.FETCH_TEMPLATES_ERROR,
                    payload: e.message
                });
                throw e;
            });
        }
    }
}

export default TemplatesActions;
