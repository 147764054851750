import { authHttpRequest } from '../utils/authAxios';

class BookmarksActions {
    static FETCH_BOOKMARKS_REQUEST = 'FETCH_BOOKMARKS_REQUEST';
    static FETCH_BOOKMARKS_SUCCESS = 'FETCH_BOOKMARKS_SUCCESS';
    static FETCH_BOOKMARKS_ERROR = 'FETCH_BOOKMARKS_ERROR';

    static ADD_BOOKMARK = 'ADD_BOOKMARK';
    static FETCH_ADD_BOOKMARK = 'FETCH_ADD_BOOKMARK';
    static DELETE_BOOKMARK = 'DELETE_BOOKMARK';
    static FETCH_DELETE_BOOKMARK = 'FETCH_DELETE_BOOKMARK';

    static getBookmarkstList() {
        return dispatch => {
            dispatch({
                type: BookmarksActions.FETCH_BOOKMARKS_REQUEST
            });
            return authHttpRequest({
                url: `/api/bookmark`,
                timeout: 2000,
                method: 'GET',
                responseType: 'json'
            }).then(responce => {
                dispatch({
                    type: BookmarksActions.FETCH_BOOKMARKS_SUCCESS,
                    payload: responce.data.data
                });
            }).catch(e => {
                dispatch({
                    type: BookmarksActions.FETCH_BOOKMARKS_ERROR,
                    payload: e.message
                });
                throw e;
            });
        }
    }

    static addToBookmarks(chart) {
        return dispatch => {
            dispatch({type: BookmarksActions.FETCH_ADD_BOOKMARK});

            return authHttpRequest({
                url: `/api/bookmark/${chart.id}`,
                timeout: 2000,
                method: 'POST',
                responseType: 'json',
            }).then(() => {
                dispatch({
                    type: BookmarksActions.ADD_BOOKMARK,
                    payload: chart
                });
            }).catch(e => {
                dispatch({
                    type: BookmarksActions.FETCH_BOOKMARKS_ERROR,
                    payload: e.message
                });
                throw e;
            });
        }
    }

    static removeFromBookmarks(chart) {
        return dispatch => {
            dispatch({type: BookmarksActions.FETCH_DELETE_BOOKMARK,});

            return authHttpRequest({
                url: `/api/bookmark/${chart.id}`,
                timeout: 2000,
                method: 'DELETE',
                responseType: 'json',
            }).then(() => {
                dispatch({
                    type: BookmarksActions.DELETE_BOOKMARK,
                    payload: chart
                });
            }).catch(e => {
                dispatch({
                    type: BookmarksActions.FETCH_BOOKMARKS_ERROR,
                    payload: e.message
                });
                throw e;
            });
        }
    }
}

export default BookmarksActions;
