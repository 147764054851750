import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { BookmarkPreview } from './components';

import './BookmarksList.scss';

class BookmarksList extends PureComponent {
    static defaultProps = {
        bookmarks: [],
        removeFromBookmarks: () => {
        }
    };

    render() {
        const {bookmarks, removeFromBookmarks} = this.props;

        const bookmarksList = bookmarks.map((bookmark, idx) => {
                return (
                    <li key={idx}
                        className='bookmarks-list__item bookmark-item'>
                        <div className='bookmark-item__header'>
                            <Link to={`/chart/${bookmark.id}`} className='bookmark-item__name'>
                                {bookmark.name}
                            </Link>
                            <div className='bookmark-item__buttons'>
                                <button onClick={() => removeFromBookmarks(bookmark)} type='button'
                                        className='pin-btn pin-btn--active'></button>
                                <button type='button' className='more-btn'></button>
                            </div>
                        </div>
                        <div className='bookmark-item__content'>
                            <BookmarkPreview chart={bookmark}/>
                        </div>
                    </li>);
            }
        );

        return (<ul className='bookmarks-list'>{bookmarksList}</ul>);
    }
}

export default BookmarksList;
