import React from 'react';
import './NoData.scss';

export default function NoData() {
    return (
        <div className='no-data'>
            <div>No data</div>
        </div>
    )
}
