/* @flow */

import moment from 'moment';
import firstOrNull from '../../utils/firstOrNull';
import {granularityEnum} from '../../constants/granularity';

export function createXAxis(gridIndex = 0): Object {
    return {
        gridIndex,
        type: 'category',
        axisLine: {
            show: false
        },
        splitArea: {
            show: true
        },
        axisTick: {
            show: false
        },
        splitLine: {
            show: false
        },
        axisLabel: {
            show: true,
            color: '#6C7081',
            formatter: (value, index) => {
                return moment(value).format('DD-MMM-YY');
            }
        }
    }
}

export function createYAxis(gridIndex = 0): Object {
    return {
        gridIndex,
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        splitLine: {
            lineStyle: {
                color: 'rgba(0, 0, 0, 0.1)'
            }
        },
        axisLabel: {
            color: '#6C7081'
        }
    }
}

export function getRangeByGranularity(granularity, ...rest): Function {
    switch (granularity) {
        case granularityEnum.month:
            return getMonthRange(...rest);
        case granularityEnum.quarter:
            return getQuarterRange(...rest);
        case granularityEnum.week:
            return getWeekRange(...rest);
        default: {
            throw new Error(`Unknown granularity: ${granularity}`);
        }
    }
}

export function getWeekRange(start, end): Array<string> {
    const startMoment = moment(start);
    const endMoment = moment(end);

    const range = Math.round(moment.duration(endMoment.diff(startMoment)).as('week'));
    return Array.from(Array(range).keys()).map((index) => {
        return startMoment.clone().add(index, 'week').format('DD MMM');
    })
}

export function getMonthRange(start, end): Array<string> {
    const startMoment = moment(start);
    const endMoment = moment(end);

    const range = Math.round(moment.duration(endMoment.diff(startMoment)).as('month'));
    return Array.from(Array(range).keys()).map((index) => {
        return startMoment.clone().add(index, 'month').format('MMM');
    })
}

export function getQuarterRange(start, end): Array<string> {
    const startMoment = moment(start);
    const endMoment = moment(end);

    const range = Math.round(moment.duration(endMoment.diff(startMoment)).as(3, 'month'));
    return Array.from(Array(range).keys()).map((index) => {
        return startMoment.clone().add(index, 'month').format('MMM');
    })
}

export function getChartDataRange(axisRange: Array, dataPoints: Array<Object>, seqNumberSelector: Function, convert: Function): Array<number> {
    return axisRange.map((_, index) => {
        const value = dataPoints[index] || null;
        if (value) {
            return convert(value)
        }
        return null;
    })
}

export function createItemRenderer(color = 'rgb(10,30,110)', xOffset = 5, fraction = null) {
    return function renderSpreadItem(params, api) {
        const xValue = api.value();
        const openPoint = api.coord([xValue, api.value(1)]);
        const closePoint = api.coord([xValue, api.value(2)]);
        const size = api.size([xValue]);

        if (fraction !== null) {
            if (fraction <= 0.5) {
                openPoint[0] -= (size[0] * (0.5 - fraction));
                closePoint[0] -= (size[0] * (0.5 - fraction));
            } else {
                openPoint[0] += (size[0] * (fraction - 0.5));
                closePoint[0] -= (size[0] * (0.5 - fraction));
            }
        } else {
            openPoint[0] += xOffset;
            closePoint[0] += xOffset;
        }

        const style = {
            stroke: color,
            lineWidth: 2
        };

        return {
            type: 'group',
            children: [{
                type: 'line',
                style,
                shape: {
                    x1: openPoint[0] - 5 + xOffset,
                    y1: openPoint[1],
                    x2: openPoint[0] + 5 + xOffset,
                    y2: openPoint[1]
                }
            }, {
                type: 'line',
                style,
                shape: {
                    x1: openPoint[0] + xOffset,
                    y1: openPoint[1],
                    x2: closePoint[0] + xOffset,
                    y2: closePoint[1]
                }
            }, {
                type: 'line',
                style,
                shape: {
                    x1: closePoint[0] - 5 + xOffset,
                    y1: closePoint[1],
                    x2: closePoint[0] + 5 + xOffset,
                    y2: closePoint[1]
                }
            }]
        };
    }
}

export function createRateSeriesNormal(name, data, color = 'rgb(10,30,110)', z = 1) {
    return {
        name,
        type: 'line',
        smooth: false,
        symbol: 'none',
        lineStyle: {
            color,
            width: 4
        },
        data,
        z: 1
    }
}

export function createRateSeriesForecast(name, data, color = 'rgb(10,30,110)', z = 1) {
    return {
        name,
        type: 'line',
        smooth: false,
        symbol: 'none',
        lineStyle: {
            type: 'dashed',
            color,
            width: 2
        },
        data,
        z
    }
}

export function createSpreadSeries(name, data, xOffset = 0, z = 2, color = '#0A1E6E', xAxisIndex = 0, yAxisIndex = 0, fraction = null) {
    return {
        name,
        xAxisIndex,
        yAxisIndex,
        type: 'custom',
        symbol: 'none',
        data,
        renderItem: createItemRenderer(color, xOffset, fraction),
        dimensions: [null, 'open', 'close'],
        encode: {
            x: 0,
            y: [1, 2]
        },
        z: 2
    }
}

export function createVolumeSeriesNormal(name, data, color = 'rgb(10,30,110)', stack = null) {
    return {
        xAxisIndex: 1,
        yAxisIndex: 1,
        name,
        type: 'bar',
        symbol: 'none',
        itemStyle: {
            color: color
        },
        stack,
        data,
        z: 1
    }
}
