import { BookmarksActions } from '../actions';

const BOOKMARKS_INITIAL_STATE = {
    list: [],
    isFetching: false,
    isFetched: false,
    error: null
};

function bookmarks(state = BOOKMARKS_INITIAL_STATE, action) {
    switch (action.type) {
        case BookmarksActions.FETCH_BOOKMARKS_REQUEST:
            return {
                ...state,
                isFetching: true,
                isFetched: false
            };
        case BookmarksActions.FETCH_BOOKMARKS_SUCCESS:
            return {
                ...state,
                list: action.payload,
                isFetching: false,
                isFetched: true
            };
        case BookmarksActions.FETCH_BOOKMARKS_ERROR:
            return {
                ...state,
                isFetching: false,
                isFetched: false,
                error: action.payload
            };
        case BookmarksActions.ADD_BOOKMARK:
            return {
                ...state,
                list: [...state.list, action.payload],
            };
        case BookmarksActions.DELETE_BOOKMARK:
            return {
                ...state,
                order: action.payload,
                list: state.list.filter(item => item.id !== action.payload.id)
            };
        default:
            return state;
    }
}

export default bookmarks;
