/* @flow */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import LaneOptions from './scenes/LaneOptions/LaneOptions';
import Logo from '../../icons/Logo';
import Chart from "./scenes/Chart/Chart";
import TradeTable from './scenes/TradeTable/TradeTable';
import ChartMenu from './scenes/ChartMenu/ChartMenu';
import {getTemplateViewMode} from '../../services/selectors/chart';
import ChartActions from '../../services/actions/ChartActions';
import {viewModeEnum} from '../../constants/viewMode';
import TimeControl from './scenes/TimeControl/TimeControl';
import {haveTimeSeriesDataPoints} from '../../services/selectors/chart';
import NoData from "./scenes/NoData/NoData";

type Props = {
    dispatch: Function,
    viewMode: string
};

function mapStateToProps(state) {
    return {
        viewMode: getTemplateViewMode(state),
        haveData: haveTimeSeriesDataPoints(state)
    }
}

class ChartManagement extends Component<Props> {

    componentDidMount() {
        const id = Number.parseInt(this.props.match.params.id);
        this.props.dispatch(ChartActions.getTemplateById(id));
    }

    render() {
        const {
            viewMode,
            haveData
        } = this.props;

        return (
            <div className='app'>
                <div className='app__top'>
                    <div className='top'>
                        <div className='top__logo'>
                            <Link to='/'><Logo/></Link>
                        </div>
                        <div className='top__user'>
                            <img
                                className='top__user-icon'
                                src='http://icons.iconarchive.com/icons/papirus-team/papirus-apps/256/google-icon.png'
                                alt='logo'
                            />
                            <div className='top__user-name'>
                                Ray Cooper
                            </div>
                        </div>
                    </div>
                </div>
                <div className='app__menu'>
                    <LaneOptions/>
                </div>
                <div className='app__content'>
                    <div className='app__content-scroll'>
                        <ChartMenu/>
                        {
                            !haveData && (
                                <NoData />
                            )
                        }
                        {
                            haveData && viewMode === viewModeEnum.chart && (
                                <Chart/>
                            )
                        }
                        {
                            haveData && viewMode === viewModeEnum.table && (
                                <TradeTable/>
                            )
                        }
                        <div className='app__slider'>
                            <TimeControl/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ChartManagement);
