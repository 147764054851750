import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';

import './RecentChartsList.scss';
import preview from '../../../../images/templates/chart.png';


class RecentChartsList extends PureComponent {
    static defaultProps = {
        charts: [],
        addToBookmarks: () => {
        },
        removeFromBookmarks: () => {
        }
    };

    bookmarkHandler = (chart) => {
        if (chart.bookmark) {
            this.props.removeFromBookmarks(chart);
        } else {
            this.props.addToBookmarks(chart);
        }
    };

    render() {
        const {charts} = this.props;

        const chartsList = charts.map((chart, idx) => {
                const bookmarkBtnClass = classNames(
                    'pin-btn', {
                        'pin-btn--active': chart.bookmark
                    });
                return (
                    <li key={idx}
                        className='recent-list__item recent-item'>
                        <Link to={`chart/${chart.id}`} className='recent-item__link'>
                            <img className='recent-item__preview' alt='' src={preview}/>
                        </Link>
                        <div className='recent-item__info'>
                            <div className='recent-item__name'>{chart.name}</div>
                            <div className='recent-item__info--bottom'>
                            <span className='recent-item__sub-text'>
                                Last change: {moment(chart.lastChangeDate).format('DD MMM')}
                            </span>
                                <div className='recent-item__buttons'>
                                    <button onClick={() => this.bookmarkHandler(chart)} type='button'
                                            className={bookmarkBtnClass}></button>
                                    <button type='button' className='more-btn'></button>
                                </div>
                            </div>
                        </div>

                    </li>);
            }
        );

        return (<ul className='recent-list'>{chartsList}</ul>);
    }
}

export default RecentChartsList;
