/* @flow */
import React from 'react';

type Props = {
    width: string,
    height: string,
    className: string
};

export default function (props: Props) {
    const width = props.width || '19px';
    const height = props.height || '19px';
    const className = props.className || '';

    return (
        <svg width={width} height={height} viewBox='0 0 19 19' version='1.1' xmlns='http://www.w3.org/2000/svg' className={className}>
            <g id='Main' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g id='2x-Rate+Volume' transform='translate(-905.000000, -141.000000)' fill='#0A1E6E'
                   fillRule='nonzero'>
                    <g id='config' transform='translate(415.000000, 141.000000)'>
                        <g id='Group' transform='translate(490.000000, 0.000000)'>
                            <g id='icon'>
                                <path
                                    d='M5,11 L6,11 L6,12 L3,12 L3,11 L4,11 L4,5 L3,5 L3,4 L6,4 L6,5 L5,5 L5,11 Z M15,11 L16,11 L16,12 L13,12 L13,11 L14,11 L14,3 L13,3 L13,2 L16,2 L16,3 L15,3 L15,11 Z M11,16 L11,17 L8,17 L8,16 L9,16 L9,7 L8,7 L8,6 L11,6 L11,7 L10,7 L10,16 L11,16 Z'
                                    id='Shape'
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
