import orderBy from 'lodash/orderBy';

import { BookmarksActions, ResentActions } from '../actions';
import { RECENT_ORDER } from '../actions/ResentActions';

const RECENT_INITIAL_STATE = {
    origList: [],
    filteredList: [],
    order: RECENT_ORDER.ASC,
    searchText: '',
    isFetching: false,
    isFetched: false,
    error: null
};

function recent(state = RECENT_INITIAL_STATE, action) {
    switch (action.type) {
        case ResentActions.FETCH_RECENT_REQUEST:
            return {
                ...state,
                isFetching: true,
                isFetched: false
            };
        case ResentActions.FETCH_RECENT_SUCCESS:
            return {
                ...state,
                origList: action.payload,
                filteredList: action.payload,
                isFetching: false,
                isFetched: true
            };
        case ResentActions.FETCH_RECENT_ERROR:
            return {
                ...state,
                isFetching: false,
                isFetched: false,
                error: action.payload
            };
        case ResentActions.FILTER_RECENT_BY_NAME:
            const searchText = action.payload.toLowerCase();
            return {
                ...state,
                searchText: searchText,
                filteredList: filterByName(state.origList, searchText)
            };
        case ResentActions.CHANGE_RECENT_ORDERING:
            return {
                ...state,
                order: action.payload,
                filteredList: orderListByDate(state.filteredList, action.payload)
            };
        case ResentActions.RESET_RECENT_FILTERS:
            return {
                ...state,
                order: RECENT_INITIAL_STATE.order,
                searchText: RECENT_INITIAL_STATE.searchText
            };
        case ResentActions.RESET_RECENT_STATE:
            return {
                ...RECENT_INITIAL_STATE
            };
        case BookmarksActions.ADD_BOOKMARK:
        case BookmarksActions.DELETE_BOOKMARK:
            const chart = action.payload;
            const newList = state.origList.map(item => {
                    if (item.id === chart.id) {
                        item.bookmark = !item.bookmark;
                    }
                    return item;
                }
            );
            return {
                ...state,
                origList: newList,
                filteredList: orderListByDate(filterByName(state.origList, state.searchText), state.order)
            };
        default:
            return state;
    }
}

const orderListByDate = (array, direction) => {
    return orderBy(array, ['lastChangeDate'], [direction])
};

const filterByName = (array, searchText) => {
    return array.filter(item => item.name.toLowerCase().includes(searchText));
};


export default recent;
