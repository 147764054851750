import { TemplatesActions } from '../actions';

const TEMPLATES_INITIAL_STATE = {
    list: [],
    isFetching: false,
    isFetched: false,
    error: null
};

function templates(state = TEMPLATES_INITIAL_STATE, action) {
    switch (action.type) {
        case TemplatesActions.FETCH_TEMPLATES_REQUEST:
            return {
                ...state,
                isFetching: true,
                isFetched: false
            };
        case TemplatesActions.FETCH_TEMPLATES_SUCCESS:
            return {
                ...state,
                list: action.payload,
                isFetching: false,
                isFetched: true
            };
        case TemplatesActions.FETCH_TEMPLATES_ERROR:
            return {
                ...state,
                isFetching: false,
                isFetched: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export default templates;
