/* @flow */

import * as React from 'react';
import classNames from 'classnames';
import Select from './Select';

function InputField({input, input: {value}, label, meta: {touched, error, active} = {}, ...props}) {
    const formGroup = classNames('form__group', {
        'form__group--has-value': !!value || active,
        'form__group--has-error': touched && !!error
    });

    return (
        <div className={formGroup}>
            <div className='form__label'>
                {label}
            </div>
            <div className='form__control'>
                <Select
                    {...props}
                    tabIndex={1}
                    {...input}
                    value={value === null || value === undefined ? '' : value}
                    onChange={input.onChange}
                />
                {
                    touched && error && (
                        <div className='form__error'>
                            {error}
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default InputField;
