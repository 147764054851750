/* @flow */
import React from 'react';

type Props = {
    width: string,
    height: string,
    className: string
};

export default function (props: Props) {
    const width = props.width || '17px';
    const height = props.height || '24px';
    const className = props.className || '';

    return (
        <svg width={width} height={height} viewBox='0 0 17 24' version='1.1' xmlns='http://www.w3.org/2000/svg' className={className}>
            <defs>
                <rect id='path-1' x='0' y='0' width='351' height='740'></rect>
            </defs>
            <g id='Main' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g id='2x-Rate-Comparison' transform='translate(-287.000000, -490.000000)'>
                    <g id='Aside' transform='translate(0.000000, 60.000000)'>
                        <mask id='mask-2' fill='white'>
                            <use xlinkHref='#path-1'></use>
                        </mask>
                        <use id='Holder' fill='#F1F3F5' xlinkHref='#path-1'></use>
                        <g id='Direction-#1' mask='url(#mask-2)'>
                            <g transform='translate(0.000000, 100.000000)' id='Destination'>
                                <g transform='translate(32.000000, 289.000000)'>
                                    <g id='Input' transform='translate(0.000000, 23.000000)'>
                                        <rect id='Rectangle' fill='#FFFFFF' x='0' y='0' width='287' height='60'></rect>
                                        <g id='Icons/Arrow-down' transform='translate(255.690909, 18.000000)'
                                           stroke='#0A1E6E' strokeLinecap='square' strokeWidth='2'>
                                            <polyline id='Path'
                                                      points='1.32727289 9.5 7.82727289 14.5 14.3272729 9.5'></polyline>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
