import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import './default.scss';
import './App.scss';
import './Top.scss';
import './Form.scss';
import {ChartManagement} from './scenes/ChartManagement';
import {Dashboard} from './scenes/Dashboard';
import LoadingBar from './components/react-redux-loading-bar/LoadingBar';

class App extends Component {
    render() {
        return (
            <React.Fragment>
                <LoadingBar
                    style={{
                        zIndex: 10,
                        backgroundColor: '#0A1E6E',
                        height: '1px'
                    }}
                />
                <Switch>
                    <Route path='/' component={Dashboard} exact/>
                    <Route path='/chart/:id' component={ChartManagement} exact/>
                </Switch>
            </React.Fragment>
        );
    }
}

export default App;
