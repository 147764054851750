import { authHttpRequest } from '../utils/authAxios';

export const RECENT_ORDER = {
    ASC: 'asc',
    DESC: 'desc'
};

class RecentActions {
    static FETCH_RECENT_REQUEST = 'FETCH_RECENT_REQUEST';
    static FETCH_RECENT_SUCCESS = 'FETCH_RECENT_SUCCESS';
    static FETCH_RECENT_ERROR = 'FETCH_RECENT_ERROR';
    static FILTER_RECENT_BY_NAME = 'FILTER_RECENT_BY_NAME';
    static CHANGE_RECENT_ORDERING = 'CHANGE_RECENT_ORDERING';
    static RESET_RECENT_FILTERS = 'RESET_RECENT_FILTERS';
    static RESET_RECENT_STATE = 'RESET_RECENT_STATE';

    static getRecentList() {
        return dispatch => {
            dispatch({
                type: RecentActions.FETCH_RECENT_REQUEST
            });
            return authHttpRequest({
                url: `/api/template/dashboard`,
                timeout: 2000,
                method: 'GET',
                responseType: 'json'
            }).then(responce => {
                dispatch({
                    type: RecentActions.FETCH_RECENT_SUCCESS,
                    payload: responce.data.data
                });
            }).catch(e => {
                dispatch({
                    type: RecentActions.FETCH_RECENT_ERROR,
                    payload: e.message
                });
                throw e;
            });
        }
    }

    static filterByName(name) {
        return {
            type: RecentActions.FILTER_RECENT_BY_NAME,
            payload: name
        };
    }

    static changeOrder(order) {
        return {
            type: RecentActions.CHANGE_RECENT_ORDERING,
            payload: order
        };
    }

    static reset() {
        return {
            type: RecentActions.RESET_RECENT_FILTERS,
        };
    }
}

export default RecentActions;
