import React, {Component} from 'react';
import './LaneOptions.scss';
import {connect} from 'react-redux';
import {SelectField} from '../../../../components/select';
import {getTradeLanesOptions, getTemplateTradeLanes} from '../../../../services/selectors/chart';
import ChartActions from '../../../../services/actions/ChartActions';
import ConfigActions from '../../../../services/actions/ConfigActions';
import firstOrNull from '../../../../utils/firstOrNull';
import colorGenerator from '../../../../utils/colorGenerator';
function mapStateToProps (state) {
    return {
        tradeLaneOptions: getTradeLanesOptions(state),
        templateTradeLanes: getTemplateTradeLanes(state)
    }
}

class LaneOptions extends Component {

    setTradeLane = (tradeLaneId) => (tradeLaneOption) => {
        this.props.dispatch(ChartActions.replaceTradeLane(tradeLaneId, tradeLaneOption.value.id)).then(() => {
            this.props.dispatch(ConfigActions.calculateChart());
        });
    };

    render() {
        const {
            tradeLaneOptions,
            templateTradeLanes
        } = this.props;

        const {
            setTradeLane
        } = this;

        const colorIterator = colorGenerator();

        return (
            <div className='lane-options'>
                <div className='lane-options__head-caption'>
                    Options
                </div>
                {
                    templateTradeLanes.map((tradeLane) => (
                        <div
                            key={tradeLane.id}
                            className='lane-options__container'
                        >
                            <div className='lane-options__caption' style={{borderColor: colorIterator.next().value}}>
                                {tradeLane.name}
                                {
                                    templateTradeLanes.length > 1 && (
                                        <div className='lane-options__caption-close'/>
                                    )
                                }
                            </div>
                            <div className='lane-options__group'>
                                <div className='lane-options__group-name'>
                                    Tradelane
                                </div>
                                <div className='lane-options__item'>
                                    <SelectField
                                        options={tradeLaneOptions}
                                        label='Select tradelane'
                                        input={{
                                            value: firstOrNull(tradeLaneOptions, (option) => option.value.id === tradeLane.id),
                                            onChange: setTradeLane(tradeLane.id)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ))
                }
                <div className='lane-options__new'>
{/*                    <div className='lane-options__btn'>
                        Add trade lane
                    </div>*/}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(LaneOptions);
