/* @flow */
import React from 'react';

type Props = {
    width: string,
    height: string,
    className: string
};

export default function (props: Props) {
    const width = props.width || '19px';
    const height = props.height || '19px';
    const className = props.className || '';

    return (
        <svg width={width} height={height} className={className} viewBox='0 0 19 19' version='1.1' xmlns='http://www.w3.org/2000/svg'>
            <g id='Main' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g id='2x-Rate+Volume' transform='translate(-415.000000, -141.000000)' fill='#0A1E6E' fillRule='nonzero'>
                    <g id='config' transform='translate(415.000000, 141.000000)'>
                        <g id='Saved'>
                            <g id='icon/add'>
                                <g id='icon'>
                                    <path d='M9.5,16 C13.0898509,16 16,13.0898509 16,9.5 C16,5.91014913 13.0898509,3 9.5,3 C5.91014913,3 3,5.91014913 3,9.5 C3,13.0898509 5.91014913,16 9.5,16 Z M9.5,17 C5.35786438,17 2,13.6421356 2,9.5 C2,5.35786438 5.35786438,2 9.5,2 C13.6421356,2 17,5.35786438 17,9.5 C17,13.6421356 13.6421356,17 9.5,17 Z M9,6 L10,6 L10,9 L13,9 L13,10 L10,10 L10,13 L9,13 L9,10 L6,10 L6,9 L9,9 L9,6 Z' id='Combined-Shape'></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
